import storage from 'local-storage-fallback';

import { PORTAL_LAST_ENTID_STORAGE_KEY } from 'apps/portal/constants';
import { KEYCLOAK_ENABLED } from 'config/env';
import { KeycloakService } from 'services/KeycloakService';
import { apiFetch, setAuthToken, clearAuthToken } from 'utils/http';

export const PortalAuthService = {
  /**
   * Get the currently logged-in user
   */
  getUser: () => apiFetch('/portal/user'),

  /**
   * Handle login for email links with auth token
   */
  login: async ({ token }: { token: string }) => {
    const response = await apiFetch('/login', {
      method: 'POST',
      body: {
        provider: 'loginToken',
        token,
        sessionMode: 'token',
      },
    });
    if (response?.success) {
      setAuthToken(response.data.token);
    }
    return response;
  },

  /**
   * Request email to log into enterprise account.
   * If email is not associated with an enterprise,
   * an informative error will say so.
   */
  requestLoginLink: ({ email }: { email: string }) =>
    apiFetch('/portal/login-code', {
      method: 'POST',
      body: { email },
    }),

  /**
   * Logout of current session
   */
  logout: async () => {
    if (KEYCLOAK_ENABLED && KeycloakService.isLoggedIn()) {
      await KeycloakService.doLogout();
      return { success: true };
    }

    const response = await apiFetch('/logout', { method: 'POST' });

    if (response?.success) {
      storage.removeItem(PORTAL_LAST_ENTID_STORAGE_KEY);
      clearAuthToken();
    }
    return response;
  },
};
