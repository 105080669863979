import { KeycloakService } from 'services/KeycloakService';
import { apiFetch } from 'utils/http';

import { IamUser } from 'types/api/iam/Iam';

export type GetUserInfoResponse = IamUser | null;

export const IamUserService = {
  /**
   * User information
   * @param userId
   * @param roleId - undefined if you're searching for logged in user
   */
  getUser: async ({ userId }: { userId: string }): Promise<GetUserInfoResponse> => {
    const res = await apiFetch(`/v2/portal/user/iam/realm/users/${userId}`, { statusCodes: true });
    if (res.success) {
      return res.data.user;
    }
    return null;
  },

  getLoggedInUser: async (): Promise<GetUserInfoResponse> => {
    const tokenParsed = KeycloakService.getTokenParsed();
    if (tokenParsed?.sub) {
      const userId = tokenParsed?.sub;
      return IamUserService.getUser({ userId });
    }
    throw new Error('No user found');
  },
};
